import './App.css';
import Sidebar from './components/sidebar/Sidebar';

function App() {
  return (
    <div className="App">
      <div className="grid-container">
        <Sidebar className="grid-sidebar" />
        <div className="header">
          <p>Ops Suite</p>
        </div>
        <div className="content" />
      </div>
    </div>
  );
}

export default App;
